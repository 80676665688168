import { genAgoTime } from "./helper";

export const DATETIME_FORMAT = "HH:mm DD/MM/YYYY";
export const DATETIME_FORMAT_SECOND = "HH:mm:ss DD/MM/YYYY";

export const PR_TYPE = {
  PR: "PR",
  NON_PR: "NON_PR",
};

export const mapRequestCICDFields = {
  reportPeriod: "reportPeriod",
  cluster: "clusterIds",
  project: "projectIds",
  profile: "profileIds",
  application: "appIds",
  group: "groups",
  "dateRange.startDate": "fromDate",
  "dateRange.endDate": "toDate",
  startDate: "fromDate",
  endDate: "toDate",
  isNonPR: "type",
};

export const COLORS = {
  SUCCEEDED: "#2EB85C",
  FAILED: "#E55353",
  PRIMARY: "#3399FF",
  WARNING: "#F9B115",
  GRAY: "#B6B7BC",
};

export const PIPELINE_STATUS = {
  SUCCEEDED: {
    label: "SUCCEEDED",
    borderColor: COLORS.SUCCEEDED,
    backgroundColor: COLORS.SUCCEEDED,
  },
  FAILED: {
    label: "FAILED",
    borderColor: COLORS.FAILED,
    backgroundColor: COLORS.FAILED,
  },
  RUNNING: {
    label: "RUNNING",
    borderColor: COLORS.PRIMARY,
    backgroundColor: COLORS.PRIMARY,
  },
};

export const STATUS_ICON_MAP = {
  SUCCEEDED: {
    color: "var(--green)",
    opacity: "rgb(0, 204, 0, 0.3)",
    icon: "mdi-chevron-down-circle",
    text: "SUCCEEDED",
  },
  FAILED: {
    color: "var(--danger)",
    opacity: "rgb(204, 51, 0, 0.3)",
    icon: "mdi-close-circle",
    text: "FAILED",
  },
  Running: {
    color: "var(--green)",
    opacity: "rgb(0, 204, 0, 0.3)",
    icon: "mdi-chevron-down-circle",
    text: "Running",
  },
  Error: {
    color: "var(--danger)",
    opacity: "rgb(204, 51, 0, 0.3)",
    icon: "mdi-close-circle",
    text: "Error",
  },
  Undeployed: {
    color: COLORS.GRAY,
    text: "Undeployed",
  },
  Degraded: {
    color: COLORS.WARNING,
    text: "Degraded",
  },
  Completed: {
    color: COLORS.SUCCEEDED,
    text: "Completed",
  },
};

export const STATUS_HONEY_COMB = {
  Running: {
    color: COLORS.SUCCEEDED,
    text: "Running",
  },
  Error: {
    color: COLORS.FAILED,
    text: "Error",
  },
  ELSE: {
    color: COLORS.WARNING,
    text: "Undeployed",
  },
};

// data response is second
// now we need a convert unit as 60 to display minutes
// we can change to 1 to show in seconds
export const CONVERT_TIME_UNIT = 60;

// this a typical value for a duration pipeline (minutes)
export const BASE_LINE_HORIZONTAL = 10;

export const TOP_RECENT_PIPELINES_HEADER = [
  {
    text: "App Name",
    sortable: true,
    value: "appName",
    class: "header-table",
  },
  {
    text: "Build Duration",
    sortable: true,
    value: "duration",
    class: "header-table",
    minWidth: "100px",
  },
  {
    text: "Time",
    sortable: true,
    value: "timestamp",
    class: "header-table",
  },
  {
    text: "",
    sortable: true,
    value: "status",
    class: "header-table",
  },
];

export const APPLICATION_HEADER = [
  {
    text: "App Name",
    sortable: true,
    value: "appName",
    class: "header-table th-app-name",
  },
  {
    text: "Status",
    sortable: true,
    value: "status",
    class: "header-table th-status",
  },
  {
    text: "Profile",
    sortable: true,
    value: "profileName",
    class: "header-table th-profile",
  },
  {
    text: "Branch/Commit",
    sortable: false,
    value: "branchCommit",
    class: "header-table th-branch-commit",
  },
  {
    text: "Image Name",
    sortable: true,
    value: "imageName",
    class: "header-table th-image-name",
  },
  {
    text: "Image Version",
    sortable: true,
    value: "imageTag",
    class: "header-table th-image-version",
  },
  {
    text: "Start",
    sortable: true,
    value: "start",
    class: "header-table th-start",
  },
  {
    text: "Duration",
    sortable: true,
    value: "duration",
    class: "header-table th-duration",
  },
];

export const TOP_CONTRIBUTOR_OF_WEEK = [
  {
    text: "Ranking",
    sortable: false,
    value: "ranking",
    align: "center",
    class: "header-table th-ranking",
  },
  {
    text: "Contributor",
    sortable: false,
    value: "commitAuthor",
    class: "header-table th-status",
  },
  {
    text: "Number Of Commits",
    sortable: false,
    value: "numberOfCommits",
    align: "center",
    class: "header-table th-profile",
  },
  {
    text: "Lines Added",
    sortable: false,
    align: "center",
    value: "locsAdded",
    class: "header-table th-branch-commit",
  },
  {
    text: "Lines Deleted",
    sortable: false,
    align: "center",
    value: "locsDeleted",
    class: "header-table th-image-name",
  },
  {
    text: "Application",
    sortable: false,
    value: "applicationName",
    class: "header-table th-image-version",
  },
];

export const TOP_CONTRIBUTOR = [
  {
    text: "Ranking",
    sortable: false,
    value: "ranking",
    align: "center",
    class: "header-table th-ranking",
  },
  {
    text: "Contributor",
    sortable: false,
    value: "commitAuthor",
    class: "header-table th-status",
  },
  {
    text: "Number Of Commits",
    sortable: false,
    value: "numberOfCommits",
    align: "center",
    class: "header-table th-profile",
  },
  {
    text: "Lines Added",
    sortable: false,
    align: "center",
    value: "locsAdded",
    class: "header-table th-branch-commit",
  },
  {
    text: "Lines Deleted",
    sortable: false,
    align: "center",
    value: "locsDeleted",
    class: "header-table th-image-name",
  },
  {
    text: "Application",
    sortable: false,
    value: "applicationName",
    class: "header-table th-image-version",
  },
];

export const TOP_REVIEWER = [
  {
    text: "Ranking",
    sortable: false,
    value: "ranking",
    align: "center",
    class: "header-table th-ranking",
  },
  {
    text: "Reviewer",
    sortable: false,
    value: "reviewer",
    class: "header-table th-status",
  },
  {
    text: "Number Of Reviews",
    sortable: false,
    value: "numberOfReviews",
    align: "center",
    class: "header-table th-profile",
  },
  {
    text: "Application",
    sortable: false,
    value: "applicationName",
    class: "header-table th-image-version",
  },
];

export const NUMBER_OF_BUILDS_OPTIONS = [5, 10, 20, 30, 50];

export const FORMAT_TIME = {
  day: "DD/MM/YYYY",
  hour: "HH:mm DD/MM/YYYY",
  month: "MM/YYYY",
};

export const RANGES_DEFAULT_DATETIME_PICKER = () => {
  // Get the current date and time
  const currentDate = new Date();

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  return {
    "Last 2 weeks": [genAgoTime(20160), currentDate],
    "Last 3 hours": [genAgoTime(180), currentDate],
    "Last 24 hours": [genAgoTime(1440), currentDate],
    "Last 2 days": [genAgoTime(2880), currentDate],
    Today: [today, currentDate],
    Yesterday: [yesterday, today],
    "Last 1 month": [genAgoTime(43200), currentDate],
    "Last 3 months": [genAgoTime(129600), currentDate],
    "Last 6 months": [genAgoTime(259200), currentDate],
    "Last 12 months": [genAgoTime(518400), currentDate],
  };
};

export const TIME_CHART_UNIT = {
  HOUR: {
    unit: "hour",
    displayFormats: {
      hour: "HH:mm",
    },
  },
  DAY: {
    unit: "day",
    displayFormats: {
      day: "DD/MM",
    },
  },
  WEEK: {
    unit: "week",
    displayFormats: {
      week: "[W]WW/YYYY",
    },
  },
  MONTH: {
    unit: "month",
    displayFormats: {
      month: "MM/YYYY",
    },
  },
  YEAR: {
    unit: "year",
    displayFormats: {
      hour: "YYYY",
    },
  },
};

export const GEN_TOP_KUBERNETES_HEADER = (isCPU, isPopupVisible) => [
  {
    text: "App Name",
    sortable: false,
    value: "name",
    class: "header-table",
    minWidth: "120px",
  },
  ...(isPopupVisible
    ? [
        {
          text: "Pod Name",
          sortable: false,
          value: "podName",
          class: "header-table",
          minWidth: "120px",
        },
      ]
    : []),
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "header-table",
    minWidth: "95px",
  },
  ...(isPopupVisible
    ? [
        {
          text: "Node",
          sortable: false,
          value: "node",
          class: "header-table",
          minWidth: "90px",
        },
      ]
    : [
        {
          text: "Replicas",
          sortable: false,
          value: "replicas",
          class: "header-table",
          minWidth: "70px",
        },
      ]),
  {
    text: "Age",
    sortable: false,
    value: "age",
    class: "header-table",
    minWidth: "90px",
  },
  {
    text: isCPU ? "CPU (millicores)" : "Memory",
    sortable: false,
    value: "value",
    class: "header-table",
    minWidth: "90px",
  },
];

export const POD_TABLE_HEADER = [
  {
    text: "Name",
    sortable: false,
    value: "name",
    class: "header-table",
    minWidth: "110px",
  },
  {
    text: "Node",
    sortable: false,
    value: "node",
    class: "header-table",
    minWidth: "80px",
  },
  {
    text: "Namespace",
    sortable: false,
    value: "namespace",
    class: "header-table",
    minWidth: "80px",
  },
  {
    text: "Ready",
    sortable: false,
    value: "ready",
    class: "header-table",
    minWidth: "60px",
  },
  {
    text: "CPU (millicores)",
    sortable: false,
    value: "cpu",
    class: "header-table",
    minWidth: "60px",
  },
  {
    text: "Memory (MiB)",
    sortable: false,
    value: "memory",
    class: "header-table",
    minWidth: "60px",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "header-table",
    minWidth: "80px",
  },
];

export const APP_STATUS = {
  Running: "Running",
  Error: "Error",
  Degraded: "Degraded",
  Completed: "Completed",
  Undeployed: "Undeployed",
};

export const APP_TYPE = {
  Deployment: "Deployment",
  StatefulSet: "StatefulSet",
};

export const KUBERNETES_SUMMARY_LABEL = {
  Total: "#3399FF",
  [APP_STATUS.Running]: "#321FDB",
  [APP_STATUS.Completed]: "#2EB85C",
  [APP_STATUS.Error]: "#E55353",
  [APP_TYPE.Deployment]: "#646569",
  [APP_TYPE.StatefulSet]: "#646569",
};

export const APP_STATUS_COLORS = {
  Running: COLORS.PRIMARY,
  Error: COLORS.FAILED,
  Degraded: COLORS.WARNING,
  Completed: COLORS.SUCCEEDED,
  Undeployed: COLORS.GRAY,
};

export const DEFAULT_VALUE_RANGE_PICKER = 14; // days
export const MAX_DURATION_START_END = 30; // days;

export const MILI_CORE_TO_NANO = 1000000;
export const MIB_TO_KI = 1024;
export const CORE_TO_MILI_CORE = 1000;

export const TIME_REFRESH = 60000; // MILISECONDS

export const REPORT_PERIOD = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
};

export const BENCH_MARKS_HEADER = [
  {
    text: "Metric",
    sortable: false,
    value: "metric",
    class: "header-table",
    width: "20%",
  },
  {
    text: "Low",
    sortable: false,
    value: "low",
    class: "header-table",
    width: "20%",
  },
  {
    text: "Medium",
    sortable: false,
    value: "medium",
    class: "header-table",
    width: "20%",
  },
  {
    text: "High",
    sortable: false,
    value: "high",
    class: "header-table",
    width: "20%",
  },
  {
    text: "Elite",
    sortable: false,
    value: "elite",
    class: "header-table",
    width: "20%",
  },
];

export const PIPELINE_TYPE = { CI: "CI", CD: "CD" };

export const DORA_BENCH_MARKS_LEVEL_CONFIG = {
  DEPLOYMENT_FREQUENCY: {
    metric: "Deployment Frequency",
    elite: "Multiple deploys per day",
    high: "Between once per day and one per week",
    medium: "Between once per week and once per month",
    low: "Fewer than once per month",
    valueRange: [
      { type: "elite", range: [1, null] },
      { type: "high", range: [1 / 7, 1] },
      { type: "medium", range: [1 / 30, 1 / 7] },
      { type: "low", range: [null, 1 / 30] },
    ],
  },
  LEAD_TIME_CHANGE: {
    metric: "Lead Time for Changes",
    elite: "Less than one day",
    high: "Between one day and one week",
    medium: "Between one week and one month",
    low: "More than one month",
    valueRange: [
      { type: "elite", range: [null, 24] },
      { type: "high", range: [24, 24 * 7] },
      { type: "medium", range: [24 * 7, 24 * 30] },
      { type: "low", range: [24 * 30, null] },
    ],
  },
  CHANGE_FAILURE_RATE: {
    metric: "Change Failure Rate",
    elite: "0 - 5%",
    high: "5 - 10%",
    medium: "10 - 15%",
    low: "15 - 100%",
    valueRange: [
      { type: "elite", range: [0, 5] },
      { type: "high", range: [5, 10] },
      { type: "medium", range: [10, 15] },
      { type: "low", range: [15, 100] },
    ],
  },
};

export const CHART_DESCRIPTION = {
  TOTAL_COUNT:
    "This chart displays the total number of CI/CD pipelines executed during a specified period, broken down by project, profile, application, and group. It helps to intuitively understand the overall activity of pipelines activated during that period.",
  AVG_BUILD_TIME:
    "This chart provides the average build time for builds executed during a specified period, categorized by project, profile, application, and group. This data is essential for estimating expected build times in specific projects or environments, and for analyzing efficiency in development and operational settings to devise strategies for improving build performance.",
  TIME_DEVIATION:
    "This chart displays the time taken for each pipeline to complete over a specified period, represented as a line graph. The x-axis indicates the time period, while the y-axis shows the duration taken by each pipeline. This graph can be used to track the flow of time in pipelines and analyze the causes of delays in specific pipelines. A dotted line on the y-axis represents the standard time level, helping to assess the duration of pipelines on specific days.",
  RUNNING_STATE:
    "This chart shows the number of pipelines executed on a daily basis. It allows users to observe how the number of pipeline executions varies during weekdays, weekends, and on specific days. This information can be used to analyze workload fluctuations and optimize operational planning.",
  NUMBER_OF_BUILDS:
    "This chart presents the total number of pipeline executions and the proportion of those that were successful or failed, in the form of a donut chart based on the queried conditions. The success rate is shown as a percentage of the total executions that were successful, and the failure rate is indicated by the percentage of failures. This provides an intuitive understanding of the efficiency of the pipelines.",
  DAILY_BUILD_FREQUENCY:
    "This chart illustrates the number of successful and failed pipeline builds per day over a specified period. The x-axis represents the dates, while the y-axis shows the number of successful builds for each date. Failed builds are indicated on the chart with a separate color. This distinct representation allows users to quickly discern the ratio of successful to failed builds on specific dates and track the variation in success and failure rates over time. Hovering over each data point provides more detailed information.",
  AVERAGE_BUILD_DURATION:
    "This chart illustrates the average execution time of pipeline builds per day over a specified period, displayed as a line graph. This includes the average times of both successful and failed builds. If failed builds are quickly terminated to prepare for the next execution, the graph will show a shorter average duration. Conversely, if failed builds are delayed for extended periods, the overall average time depicted in the graph will increase. This data aids in assessing the overall efficiency of the build process.",
  LONGEST_10_BUILDS:
    "This table presents the top 10 longest builds. It details the duration of each build and the date when it was requested, including the application name, build duration, and request time. This data serves as a fundamental resource for tracking issues that may be causing delays in the build process for specific applications.",
  HONEYCOMB_VIEW: `This Honeycomb View provides the most recent pipeline status of applications in a honeycomb layout. The statuses are shown as 'Running', 'Error', and 'Undeployed'. By double-clicking on an item with your mouse, you can navigate to the detailed pipeline status screen for that application.`,
  AGGREGATE_INFORMATION_OF_MULTIPLE_APPLICATIONS:
    "This scatter plot graph represents the distribution of all pipelines requested over time, with each dot signifying an individual build. This allows for an intuitive understanding of the quantity of builds and their respective durations. The graph includes a dotted line on the y-axis to represent the standard time benchmark, which can be used to evaluate if the builds are completing within the expected timeframe. If the same application shows variable build durations, this can indicate the impact of the deployment environment on performance, serving as a metric for improvement.",
  APPLICATION_LIST: `This list provides the latest pipeline execution information for applications. The table includes the application name, status, profile, branch/commit details, image name and version, start time, and duration of execution. This data can help identify whether an application is operating smoothly or experiencing issues. Selecting an 'App Name' will navigate to a screen offering more detailed information about that particular application.`,
  TIME_DURATION_TRENDS_OVER_RECENT_N_BUILDS_DEPLOYMENTS: `This chart shows the duration of the most recent build/deployment operations. Users can select from options of 5, 10, 20, 30, or 50 to view the time trends of recent pipelines. The chart differentiates between successful and failed builds with distinct colors. The x-axis indicates the start time of each build/deployment, and the y-axis represents the duration taken. This information is useful for evaluating the recent performance of the build/deployment processes.`,
  LONGEST_10_BUILDS_APPDETAIL:
    "This chart table provides detailed information about the 10 longest-running builds requested during the specified period. It includes the status of success or failure, branch/commit details, the name and version of the image used for deployment, the start time, and the duration of each build. Users can click on the status icon to view a detailed execution history for a build, and the commit details link to the corresponding Git service. Accessing detailed information and Git service may require proper system permissions.",
  OVERALL_DORA_METRIC: `The 'Overall DORA Metric' table displays the current level of key DevOps Research 
    and Assessment (DORA) metrics, which measure development and operational 
    efficiency. These metrics, including 'Deployment Frequency', 'Lead Time for Changes', 
    and 'Change Failure Rate', are categorized into four levels: Low, Medium, High, and 
    Elite. Each metric reflects the current standing of the organization, with the expanded 
    view allowing users to see all levels and thereby evaluate the organization’s 
    performance at a glance`,
  DEPLOYMENT_FREQUENCY: `The 'Deployment Frequency' chart is a key metric from DORA that measures how 
    frequently a team is deploying code changes to the production environment. A higher 
    frequency of deployment typically indicates a fast feedback loop and higher 
    productivity, suggesting a well-established DevOps culture. This chart graphically 
    displays the count of each deployment over the reporting period (day, week, month, 
    year), counting only successful deployments. The x-axis details the reporting period, 
    the y-axis the number of deployments, and the number in the top left corner reflects the 
    average deployment rate per time period. This metric helps to assess how well an 
    organization is maintaining its deployment velocity and where there may be room for 
    improvement.`,
  LEAD_TIME_FOR_CHANGE: `The 'Lead Time for Change' is one of the DORA metrics that measures the time taken 
    from code commit to being deployed in the production environment. This duration 
    reflects the time it takes for changes to start delivering value to customers and is a 
    crucial indicator of the efficiency of the development process. A shorter average Lead 
    Time indicates faster value delivery and quicker feedback collection. Conversely, a 
    longer Lead Time can suggest bottlenecks or inefficiencies in the process. The value 
    presented in this chart represents the average Lead Time in hours, reflecting the 
    overall speed and efficiency of the development and deployment processes during the 
    given period.`,
  CHANGE_FAILURE_RATE: `The 'Change Failure Rate' chart implements a DORA metric that represents the 
    percentage of changes leading to failure in the development process. This metric is 
    derived from the incidents or issues that occur post-implementation of changes in 
    production, serving as an indicator of DevOps maturity and process quality. The x-axis 
    displays the reporting period set in DAY, WEEK, MONTH, YEAR intervals, while the y-
    axis shows the percentage of error rates for CI (Continuous Integration) and CD 
    (Continuous Deployment).`,
  CYCLE_TIME_OVERVIEW: `The 'Cycle Time Overview' chart visually represents the total time taken to complete a 
    task from start to finish within the development process. This chart breaks down the 
    cycle time into four distinct phases: Coding time, Review time, Build time, and 
    Deployment time. Each phase is measured in hours, providing a detailed view of where 
    time is being spent within the workflow.
    <ul style="list-style: initial;">
    <li>Coding Time: The duration spent writing and developing code.</li>
    <li>Review Time: The period used for reviewing and approving the written code.</li>
    <li>Build Time: The time taken to compile and build the code into a deployable product.</li>
    <li>Deployment Time: The final stage where the product is deployed to the production 
    environment.</li>
    </ul>
    This chart is crucial for identifying bottlenecks and areas for improvement in the 
    development process. It allows teams to optimize their workflow, enhance efficiency, 
    and reduce overall cycle time. Each phase's time is displayed in hours, and by 
    monitoring these phases, teams can achieve a more streamlined and effective 
    development cycle.`,
  CI_NUMBER_OF_EXECUTION: `The 'CI Number Of Execution' chart provides a summary of the continuous integration 
    (CI) execution results. This chart displays two key metrics: the number of failed 
    executions and the average time to succeed in minutes.
    <ul style="list-style: initial;">
    <li>Failed Executions: The count of CI runs that failed during the specified period.</li>
    <li>Minutes to Succeed: The average time in minutes taken for CI runs to complete 
    successfully.</li>
    </ul>
    This chart is essential for monitoring the health and efficiency of the CI process. By 
    tracking the number of failures and the time to succeed, teams can quickly identify 
    issues and make necessary improvements to maintain a smooth and efficient CI 
    pipeline.`,
  CD_NUMBER_OF_EXECUTION: `The 'CD Number Of Execution' chart provides a summary of the continuous 
    deployment (CD) execution results. This chart displays two key metrics: the number of 
    failed executions and the average time to succeed in minutes.
    <ul style="list-style: initial;">
    <li>Failed Executions: The count of CD runs that failed during the specified period.</li>
    <li>Minutes to Succeed: The average time in minutes taken for CD runs to complete 
    successfully.</li>
    </ul>
    This chart is essential for monitoring the health and efficiency of the CD process. By 
    tracking the number of failures and the time to succeed, teams can quickly identify 
    issues and make necessary improvements to maintain a smooth and efficient CD 
    pipeline.`,
  BUILD_TIME: `The 'Build Time' chart visually represents the average time taken to complete the build 
    process over a specified period. This chart displays build times in hours over several 
    months.
    <ul style="list-style: initial;">
    <li>Average Build Time: The chart shows the average build time.</li>
    <li>Historical Data: The line graph represents the build times for each build over a 
    certain period. Each point on the graph indicates the duration of a successful build.</li>
    <li>Threshold Line: The orange dashed line represents a threshold or target build time 
    set as an example. It serves as a benchmark to quickly assess how many builds 
    exceed this time.</li>
    </ul>
    This chart is crucial for monitoring and improving the efficiency of the build process. By 
    analyzing historical build times, teams can identify trends, detect anomalies, and make 
    informed decisions to optimize the build process. The x-axis represents the timeline, 
    while the y-axis shows the build time in hours. Regular monitoring helps ensure that 
    the build process remains within acceptable time limits, contributing to a more efficient 
    development cycle.`,
  DEPLOYMENT_TIME: `The 'Deployment Time' chart visually represents the average time taken to complete 
    the deployment process over a specified period. This chart displays deployment times 
    in hours over several months.
    <ul style="list-style: initial;">
    <li>Average Deployment Time: The chart shows the average time for the deployment 
    process.</li>
    <li>Historical Data: The line graph represents the deployment times for each 
    deployment over a certain period. Each point on the graph indicates the duration of 
    a successful deployment.</li>
    <li>Threshold Line: The orange dashed line represents a threshold or target 
    deployment time set as an example. It serves as a benchmark to quickly assess 
    how many deployments exceed this time.</li>
    <li>Success and Failure Indicators: The graph distinguishes between successful 
    (SUCCEEDED) and failed (FAILED) deployments using different colors.</li>
    </ul>
    This chart is crucial for monitoring and improving the efficiency of the deployment 
    process. By analyzing historical deployment times, teams can identify trends, detect 
    anomalies, and make informed decisions to optimize the deployment process. The x-
    axis represents the timeline, while the y-axis shows the deployment time in hours. 
    Regular monitoring helps ensure that the deployment process remains within 
    acceptable time limits, contributing to a more efficient development cycle.`,
  CYCLE_TIME_BREAKDOWN: `The 'Cycle Time Breakdown' chart is a table format chart that provides a detailed view 
    of the cycle time for individual PRs (Pull Requests). This chart includes various metrics 
    for each PR, helping to analyze and monitor each stage of the development process.
    <ul style="list-style: initial;">
    <li>Merge Date: Indicates the date the PR was merged.</li>
    <li>PR Title: Indicates the title of the PR.</li>
    <li>PR Author: Indicates the name of the person who created the PR.</li>
    <li>Coding Time: Indicates the time spent writing the code.</li>
    <li>Review Time: Indicates the time spent reviewing the code.</li>
    <li>URL: Provides the GitHub link to the PR.</li>
    </ul>
    This table allows for the analysis and improvement of cycle times by examining the 
    details of each PR. For example, it helps identify PRs with long coding and review 
    times, enabling process optimization.`,
  TOP_MOST_ACTIVELY_DEVELOPED_APPLICATIONS_RECENTLY: `The 'Top Most Actively Developed Applications Recently' chart visualizes the 
    development activity of various applications based on the number of lines of code 
    (LOC) added or modified over a specific period.
    <ul style="list-style: initial;">
    <li>Y-axis (LOC): Represents the number of lines of code added or modified. Each 
    increment indicates the volume of changes in the codebase.</li>
    <li>X-axis (App): Lists the applications being tracked for development activity.</li>
    <li>Data Points: Each point on the graph corresponds to the activity level of a particular 
    application. Different colors or markers represent different applications.</li>
    <li>Legend: Explains the colors or markers used to differentiate the applications.</li>
    </ul>
    This chart helps in identifying which applications have seen the most development 
    activity recently. By monitoring this, teams can allocate resources effectively and 
    prioritize their development efforts.`,
  TOP_RECENT_PRS_WITH_SIGNIFICANT_CODE_CHANGES: `The 'Top Recent PRs With Significant Code Changes' chart visualizes the most 
    impactful pull requests (PRs) based on the number of lines of code (LOC) added or 
    modified recently.
    <ul style="list-style: initial;">
    <li>Y-axis (LOC): Represents the number of lines of code added or modified. Each 
    increment indicates the volume of changes in the codebase.</li>
    <li>X-axis (App): Lists the applications associated with the significant pull requests.</li>
    <li>Data Points: Each point on the graph corresponds to the activity level of a particular 
    pull request. Different colors or markers represent different applications.</li>
    <li>Legend: Explains the colors or markers used to differentiate the applications.</li>
    </ul>
    This chart helps in identifying which pull requests have had the most significant impact 
    on the codebase recently. By monitoring this, teams can prioritize code reviews, 
    understand the scale of changes, and manage code quality effectively.`,
  COMMIT_FREQUENCY: `The ‘Commit Frequency’ chart visualizes the productivity of developers by showing the frequency of code commits across different applications.
    <ul style="list-style: initial;">
      <li>Y-axis (Changes): Represents the number of changes made in each commit.</li>
      <li>X-axis (Date): Displays the timeline, indicating when the commits were made.</li>
      <li>Data Points: Each point on the graph corresponds to a specific commit, showing its frequency and magnitude.</li>
      <li>Purpose: This chart helps in assessing developer productivity by tracking how often code changes occur and identifying peak periods of activity.</li>
    </ul>`,
  CODING_TIME: `The ‘Coding Time’ chart illustrates the time spent on specific commits for each application.
    <ul style="list-style: initial;">
      <li>Y-axis (Hours): Represents the amount of time spent on each commit.</li>
      <li>X-axis (App): Lists the applications related to the commits.</li>
      <li>Data Points: Each bar indicates the total coding time for a particular application.</li>
      <li>Purpose: This chart helps in analyzing how much time developers are spending on different applications, allowing for better resource allocation and productivity assessment</li>
    </ul>`,
  AVERAGE_REVIEW_DURATION: `The ‘Average Review Duration’ chart displays the cumulative time spent on reviewing Git pull requests for each application.
    <ul style="list-style: initial;">
      <li>Y-axis (Hours): Represents the total review time spent per application.</li>
      <li>X-axis (App): Lists the applications associated with the pull requests.</li>
      <li>Data Points: Each bar indicates the average review duration for a specific application.</li>
      <li>Purpose: This chart helps in understanding the review workload across different applications, facilitating better time management and process optimization.</li>
    </ul>`,
  HISTORICAL_WORK_ON_BRANCHES: `The ‘Historical Work on Branches’ table provides a comprehensive overview of branch activity and merge history.
    Columns:
    <ul style="list-style: initial;">
      <li>Branch Name: The name of each branch.</li>
      <li>Create Date: The date when the branch was created.</li>
      <li>Last Commit: The date of the most recent commit on the branch.</li>
      <li>Number of Commits: Total number of commits made to the branch.</li>
      <li>Number of Contributors: Number of contributors who have worked on the branch.</li>
      <li>Merge Status: Indicates whether the branch has been successfully merged.</li>
      <li>URL: A link to view more details about the branch.</li>
      <li>Purpose: This table helps in tracking the development progress and collaboration on different branches, providing insights into team activity and project evolution.</li>
    </ul>`,
  AVERAGE_PRS_SIZE: `The ‘Average PRs Size’ chart visualizes the size of pull requests in terms of code changes across different applications.
    <ul style="list-style: initial;">
      <li>Y-axis (Changes): Represents the number of code changes, including additions and deletions.</li>
      <li>X-axis (PRs): Lists the pull requests associated with various applications.</li>
      <li>Data Points: Each bar shows the size of a pull request, with details such as lines of code added, deleted, and the number of files changed.</li>
      <li>Purpose: This chart helps in evaluating the complexity and impact of pull requests, aiding in better project management and review processes.</li>
    </ul>`,
  ONGOING_PRS: `The ‘Ongoing PRs’ table provides a detailed overview of currently active pull requests.
    Columns:
    <ul style="list-style: initial;">
      <li>PR Title: The title of each pull request, indicating its purpose or feature.</li>
      <li>Author: The developer who created the pull request.</li>
      <li>Created Date: The date and time when the pull request was initiated.</li>
      <li>Number of Reviewers: Indicates how many reviewers are assigned to each pull request.</li>
      <li>Number of Comments: Shows the number of comments made on the pull request.</li>
      <li>Status: The current status of the pull request (e.g., open, closed).</li>
      <li>URL: A link to view the pull request in detail on GitHub.</li>
      <li>Purpose: This table helps in tracking the progress and status of ongoing development work, facilitating better project management and collaboration.</li>
    </ul>`,
  TOP_N_CONTRIBUTORS_BY_PRS: `The ‘Top 10 Contributors by PRs’ table highlights the developers with the most code contributions during the selected time period.
    Columns:
    <ul style="list-style: initial;">
      <li>Contributor Name: The name of each developer contributing to the project.</li>
      <li>Number of PRs: Total number of pull requests submitted by the contributor.</li>
      <li>Lines of Code Added: The number of lines of code added by the contributor.</li>
      <li>Lines of Code Deleted: The number of lines of code removed by the contributor.</li>
      <li>Number of Files Changed: Total files modified by the contributor.</li>
      <li>URL: A link to view more details about each contributor's activity.</li>
      <li>Purpose: This table provides insights into the most active contributors, helping teams recognize key contributors and manage resources effectively.</li>
    </ul>`,
  TOP_N_MOST_DEPLOYED_APPLICATIONS: `The ‘Top 10 Most Deployed Applications’ table provides an overview of the applications with the highest number of deployments.
    Columns:
    <ul style="list-style: initial;">
      <li>Application Name: The name of each application.</li>
      <li>Number of Deployments: Total number of times the application has been deployed.</li>
      <li>Last Deployment Date: The date and time of the most recent deployment.</li>
      <li>Version: The current version of the deployed application.</li>
      <li>Deployment Environment: The environment where the application is deployed.</li>
      <li>URL: A link to view more details about each deployment.</li>
      <li>Purpose: This table helps in identifying the most actively deployed applications, providing insights into deployment frequency and version management.</li>
    </ul>`,
  TOP_N_MOST_COMMITED_APPLICATIONS: `The ‘Top 10 Most Committed Applications’ table provides an overview of the applications with the highest number of code commits.
    Columns:
    <ul style="list-style: initial;">
      <li>Application Name: The name of each application.</li>
      <li>Number of Commits: Total number of commits made to the application.</li>
      <li>Last Commit Date: The date and time of the most recent commit.</li>
      <li>Number of Contributors: Number of developers who have contributed to the application.</i>
      <li>Lines of Code Changed: Total lines of code that have been added or modified.</li>
      <li>URL: A link to view more details about each application's commit history.</li>
      <li>Purpose: This table helps in identifying which applications are undergoing the most development activity, providing insights into team focus and project priorities.</li>
    </ul>`,
  TOP_N_MOST_BUILED_APPLICATIONS: `The ‘Top 10 Most Built Applications’ table provides insights into the applications with the highest build frequency.
    Columns:
    <ul style="list-style: initial;">
      <li>Application Name: The name of each application.</li>
      <li>Number of Builds: Total number of builds completed for the application.</li>
      <li>Last Build Date: The date and time of the most recent build.</li>
      <li>Build Status: Indicates whether the last build was successful or failed.</li>
      <li>Average Build Time: The average time taken to complete a build.</li>
      <li>URL: A link to view more details about each application's build history.</li>
      <li>Purpose: This table helps in identifying which applications are being built most frequently, offering insights into development focus and potential areas requiring attention.</li>
    </ul>`,
  LINE_OF_CODE: `Monitoring the growth and change in the codebase helps evaluate development 
      progress. An increase in LOC reflects the addition of new features or modifications, 
      helping to understand changes in project scope or complexity.
      <ul style="list-style: initial;">
        <li>Y-axis (Lines of Code): Represents the total number of lines of code in the project.</li>
        <li>X-axis (Time): Shows the timeline, indicating the dates when code line 
        measurements were taken.</li>
      </ul>`,
  CODE_CHURN: `Used to evaluate code stability and modification frequency. High churn may indicate 
      periods of active feature updates or refactoring. Monitoring this graph helps in efforts to 
      maintain code stability.
      <ul style="list-style: initial;">
        <li>Y-axis (Changes): Represents the number of code changes, including additions, deletions, and modifications.</li>
        <li>X-axis (Date): Shows the dates when code changes occurred.</li>
      </ul>`,
  RELIABILITY: `This metric evaluates code reliability, with a higher bug count indicating lower stability. 
      It helps identify areas in need of quality improvement.
      <ul style="list-style: initial;">
        <li>Represents the total number of identified bugs. A decreasing trend in bug count indicates improving code quality.</li>
      </ul>`,
  SECURITY: `This metric evaluates security risks, with fewer vulnerabilities indicating enhanced 
      security. A higher vulnerability count implies a greater risk of data breaches or system 
      compromises.
      <ul style="list-style: initial;">
        <li>Displays the total count of identified security vulnerabilities.</li>
      </ul>`,
  SECURITY_HOTSPOTS: `Helps identify potentially vulnerable areas in the code for review and mitigation to 
      enhance security.
      <ul style="list-style: initial;">
        <li>Shows the number of areas in the code that require security review.</li>
      </ul>`,
  TEST_COVERAGE: `Test coverage is crucial for assessing code stability. Higher coverage means more 
      code is tested, reducing the likelihood of unexpected bugs.
      <ul style="list-style: initial;">
        <li>Represents the percentage of code that is covered by tests.</li>
      </ul>`,
  MAINTAINABILITY_DEBT: `Technical debt reflects long-term maintenance burden, with a higher value indicating 
      more areas needing improvement.
      <ul style="list-style: initial;">
        <li>Shows the estimated time (in days) needed for code maintenance.</li>
      </ul>`,
  MAINTAINABILITY_CODE_SMELLS: `Reducing code smells improves code quality and eases maintenance.
      <ul style="list-style: initial;">
        <li>Shows the total count of code smells in the codebase.</li>
      </ul>`,
  DUPLICATED_BLOCKS: `Reducing code duplication improves readability and maintainability.
      <ul style="list-style: initial;">
        <li>Displays the total count of duplicated code blocks.</li>
      </ul>`,
  DUPLICATED_LINES: `Lower duplication percentage eases maintenance and improves code efficiency.
      <ul style="list-style: initial;">
        <li>Shows the percentage of duplicated lines in the codebase.</li>
      </ul>`,
  TOP_CONTRIBUTOR_OF_WEEK: `Recognizes team members' contributions and encourages higher engagement by 
      acknowledging top contributors.
      <ul style="list-style: initial;">
        <li>Displays top contributors of the week based on commit frequency, lines added, and other contributions.</li>
      </ul>`,
  TOP_CONTRIBUTOR_OVER_TIME: `Tracks long-term contributions to identify sustained involvement and encourage performance.
      <ul style="list-style: initial;">
        <li>Tracks long-term contributions of team members to show continuous impact over time.</li>
      </ul>`,
  TOP_REVIEWER: `Code reviews are vital for maintaining and improving code quality; highlighting frequent 
      reviewers encourages contributions to quality control.
      <ul style="list-style: initial;">
        <li>Displays the top code reviewers based on review frequency.</li>
      </ul>`,
};

export const FIELD_EXPORT_CONFIG = {
  TIME_DEVIATION: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
  RUNNING_STATE: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Quantity",
    timestamp: "Excuted Time",
  },
  DAILY_BUILD_FREQUENCY: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Quantity",
    timestamp: "Excuted Time",
  },
  AVERAGE_BUILD_DURATION: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Avg duration (minutes)",
    timestamp: "Excuted Time",
  },
  LONGEST_10_BUILDS: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    duration: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
  AGGREGATE_INFORMATION_OF_MULTIPLE_APPLICATIONS: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
  APPLICATION_LIST: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    imageName: "Image Name",
    imageTag: "Image Tag",
    branch: "Branch",
    status: "Status",
    duration: "Duration (minutes)",
    pipeLineMessage: "Message",
    url: "Commit",
  },
  HONEYCOMB_VIEW: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    viewReplica: "Replica",
    status: "Status",
    type: "Type",
    namespace: "Namespace",
  },
  TIME_DURATION_TRENDS_OVER_RECENT_N_BUILDS_DEPLOYMENTS: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Duration (minutes)",
    timestamp: "Excuted Time",
  },
  DEPLOYMENT_FREQUENCY: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Duration (minutes)",
    finishDate: "Excuted Time",
  },
  LEAD_TIME_CHANGE: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    y: "Duration (hours)",
    deployedDate: "Deployed Date",
  },
  CHANGE_FAILURE_RATE: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    numberCI: "Number CI",
    ciFailed: "CI Failed",
    numberCD: "Number CD",
    cdFailed: "CD Failed",
    finishDate: "Excuted Time",
  },
  BUILD_TIME: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Build Time (minutes)",
    x: "Excuted Time",
  },
  DEPLOYMENT_TIME: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    status: "Status",
    y: "Deployment Time (minutes)",
    x: "Excuted Time",
  },
  CYCLE_TIME_BREAKDOWN: {
    mergeDate: "Merge Date",
    prTitle: "PR Title",
    prAuthor: "PR Author",
    codingDuration: "Coding Time",
    reviewingDuration: "Review Time",
    url: "URL",
  },
  TOP_DEVELOPED_APP: {
    projectName: "Project",
    profileName: "Profile",
    x: "Application",
    repo: "Repository",
    y: "Line Of Code",
  },
  TOP_RECENT_PR: {
    projectName: "Project",
    profileName: "Profile",
    appName: "Application",
    repo: "Repository",
    branch: "Branch",
    x: "PR Title",
    y: "Line Of Code",
  },
};

export const COLOR_COLLECTOR = [
  "#0095AC", // Common Cyan
  "#FD7E14", // Orange
  "#E83E8C", // Pink
  "#FFD700", // Gold
  "#8A2BE2", // Blue Violet
  "#FF4500", // Orange Red
  "#2E8B57", // Sea Green
  "#00CED1", // Dark Turquoise
  "#FF69B4", // Hot Pink
  "#CD5C5C", // Indian Red
  "#4B0082", // Indigo
  "#7FFF00", // Chartreuse
  "#FF1493", // Deep Pink
  "#1E90FF", // Dodger Blue
  "#00FA9A", // Medium Spring Green
  "#FF6347", // Tomato
  "#4682B4", // Steel Blue
  "#DA70D6", // Orchid
  "#32CD32", // Lime Green
  "#00FF7F", // Spring Green
  "#FF00FF", // Magenta
  "#00FFFF", // Cyan
  "#7B68EE", // Medium Slate Blue
  "#DC143C", // Crimson
  "#FF8C00", // Dark Orange
  "#8B0000", // Dark Red
  "#00BFFF", // Deep Sky Blue
  "#ADFF2F", // Green Yellow
  "#DDA0DD", // Plum
  "#F08080", // Light Coral
  "#FF7F50", // Coral
  "#B0E0E6", // Powder Blue
  "#DAA520", // Goldenrod
  "#778899", // Light Slate Gray
  "#BA55D3", // Medium Orchid
  "#FFB6C1", // Light Pink
  "#FA8072", // Salmon
  "#E9967A", // Dark Salmon
  "#9400D3", // Dark Violet
  "#FFDEAD", // Navajo White
  "#98FB98", // Pale Green
  "#AFEEEE", // Pale Turquoise
  "#DB7093", // Pale Violet Red
  "#FFDAB9", // Peach Puff
  "#CD853F", // Peru
  "#FFC0CB", // Pink
  "#DDA0DD", // Plum
  "#B0C4DE", // Light Steel Blue
];

export const DEFAULT_CUSTOMIZED_METRIC_DORA_CYCLETIME = {
  "DORA Metrics": {
    "Overall DORA Metric": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Deployment Frequency": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Lead time for change": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Change Failure Rate": {
      show: true,
      fixed: false,
      span: 1,
    },
  },
  "Cycle Time Metrics": {
    "CI Number of Execution": {
      show: true,
      fixed: false,
      span: 1,
    },
    "CD Number of Execution": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Cycle Time Overview": {
      show: true,
      fixed: false,
      span: 2,
    },
    "Build Time": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Deployment Time": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Cycle Time Breakdown": {
      show: true,
      fixed: false,
      span: 2,
    },
  },
  "Top App and PR": {
    "Top most actively developed applications recently": {
      show: true,
      fixed: false,
      span: 2,
    },
    "Top recent PRs with significant code changes": {
      show: true,
      fixed: false,
      span: 2,
    },
  },
};

export const CUSTOMIZED_METRIC_CODE_QUALITY = {
  "Code Quality": {
    "Commit Frequency": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Coding Time": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Average Review Duration": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Historical Work On Branches": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Average PRs Size": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Ongoing PRs": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Contributors by PRs": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Most Deployed Applications": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Most Committed Applications": {
      show: true,
      fixed: false,
      span: 1,
    },
    "Top 10 Most Builed Applications": {
      show: true,
      fixed: false,
      span: 1,
    },
  },
};

export const CUSTOMIZED_METRIC_CODE_QUALITY_2 = {
  "Code Quality": {
    "Line Of Code": {
      show: true,
      row: 1,
    },
    "Code Churn": {
      show: true,
      row: 1,
    },
    Reliability: {
      show: true,
    },
    Security: {
      show: true,
    },
    "Security Hotspots": {
      show: true,
    },
    Test: {
      show: true,
    },
    "Maintainability - Debt": {
      show: true,
    },
    "Maintainability - Code Smells": {
      show: true,
    },
    "Duplicated Blocks": {
      show: true,
    },
    "Duplicated Lines": {
      show: true,
    },
    "Top Contributor Of Week": {
      show: true,
    },
    "Top Contributor Over Time": {
      show: true,
    },
    "Top Reviewer": {
      show: true,
    },
  },
};

export const CUSTOMIZED_METRIC_APPLICATION_STATUS = {
  "Application Status": {
    "Top 10 Highest Usage CPU": {
      show: true,
      col: 1,
    },
    "Top 10 Highest Usage Memory": {
      show: true,
      col: 1,
    },
    "Application List": {
      show: true,
    },
    "CPU Usage": {
      show: true,
      col: 1,
    },
    "Memory Usage": {
      show: true,
      col: 1,
    },
  },
};

export const CUSTOMIZED_METRIC_CICD_STATUS = {
  "CI/CD Status": {
    "Total count": {
      show: true,
      row: 1,
      col: 1,
    },
    "Avg. build time": {
      show: true,
      row: 1,
      col: 1,
    },
    "Time deviation": {
      show: true,
      row: 1,
      col: 2,
    },
    "Running State": {
      show: true,
      row: 1,
      col: 2,
    },
    "Honeycomb View": {
      show: true,
      row: 1,
      col: 3,
    },
    "Number of builds": {
      show: true,
      row: 1,
      col: 1,
    },
    "Daily build frequency": {
      show: true,
      row: 1,
      col: 2,
    },
    "Average build duration": {
      show: true,
      row: 1,
      col: 2,
    },
    "Longest 10 builds": {
      show: true,
      row: 1,
      col: 2,
    },
    "Aggregate Info": {
      show: true,
    },
    "Application List": {
      show: true,
    },
  },
};

export const CODE_QUALITITY_TABLE = {
  ON_GOING_PR_TABLE_HEADER: [
    {
      text: "PR Title",
      sortable: false,
      value: "prTitle",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "PR Author",
      sortable: false,
      value: "prAuthor",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Created Date",
      sortable: false,
      value: "prCreatedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Number of Reviewers",
      sortable: false,
      value: "numberOfReviewers",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Number of comments",
      sortable: false,
      value: "numberOfComments",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "Status",
      sortable: false,
      value: "status",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  HISTORICAL_BRANCH_TABLE_HEADER: [
    {
      text: "Branch Name",
      sortable: false,
      value: "branchName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Create Date",
      sortable: false,
      value: "createdDate",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Commit",
      sortable: false,
      value: "lastCommittedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Number of Commits",
      sortable: false,
      value: "noOfCommits",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Number of Contributors",
      sortable: false,
      value: "noOfContributors",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "Merge Status",
      sortable: false,
      value: "status",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  CONTRIBUTORS_TABLE_HEADER: [
    {
      text: "Contributor Name",
      sortable: false,
      value: "userName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of PRs",
      sortable: false,
      value: "noOfPRs",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Lines of Code Added",
      sortable: false,
      value: "locAdded",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Line of Code Deleted",
      sortable: false,
      value: "locDeleted",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Number of Files Changed",
      sortable: false,
      value: "noFiles",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  MOST_DEPLOY_TABLE_HEADER: [
    {
      text: "Application Name",
      sortable: false,
      value: "appName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of Deployments",
      sortable: false,
      value: "noOfDeployments",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Deployment Date",
      sortable: false,
      value: "lastDeployedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Version",
      sortable: false,
      value: "version",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Deployment Environment",
      sortable: false,
      value: "profileName",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  MOST_COMMITED_TABLE_HEADER: [
    {
      text: "Application Name",
      sortable: false,
      value: "applicationName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of Commits",
      sortable: false,
      value: "noOfCommits",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Commit Date",
      sortable: false,
      value: "lastCommittedDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Number of Contributors",
      sortable: false,
      value: "noOfContributor",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "Line of Code Changed",
      sortable: false,
      value: "noOfChanged",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
  MOST_BUILD_TABLE_HEADER: [
    {
      text: "Application Name",
      sortable: false,
      value: "appName",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Number of Builds",
      sortable: false,
      value: "noOfBuild",
      class: "header-table",
      minWidth: "140px",
    },
    {
      text: "Last Build Date",
      sortable: false,
      value: "lastBuiltDate",
      class: "header-table",
      minWidth: "70px",
    },
    {
      text: "Build Status",
      sortable: false,
      value: "status",
      class: "header-table",
      minWidth: "150px",
    },
    {
      text: "avg Build Time",
      sortable: false,
      value: "builtDuration",
      class: "header-table",
      minWidth: "100px",
    },
    {
      text: "URL",
      sortable: false,
      value: "url",
      class: "header-table",
      minWidth: "100px",
    },
  ],
};
