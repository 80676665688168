// @ts-check
import axios from "axios";
import { request } from "../request";
import { HttpClient } from "../httpClient";

export const workspaceInstance = axios.create({
  baseURL: process.env.VUE_APP_WORKSPACE_API_HOST
    ? process.env.VUE_APP_WORKSPACE_API_HOST
    : "kube-proxy.amdp-dev.skamdp.org",
});

workspaceInstance.interceptors.request.use((config) => {
  const token = HttpClient.getToken();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token,
    },
  };
});

/**
 * 워크스페이스 리퀘스트 파라메터
 * @param {import('../request').RequestParams} param0
 * @returns
 */
const workspaceRequest = async ({ ...args }) =>
  await request({
    ...args,
    originalResponse: true,
    instance: workspaceInstance,
  });

/*
 * Workspace 등록
 * @param {{ projectId:string }} param0
 */
export const addWorkspace = async ({
  name,
  displayName,
  description,
  projectId,
  projectName,
  profileId,
  profileName,
  namespaces,
  username,
  resourceQuota,
  selectedUsers,
  selectedInfraClass,
  selectedNodeTaint,
  selectedStorageTypes,
  defaultSettings,
  selectedAdministrator,
  selectedImages,
}) =>
  await workspaceRequest({
    method: "post",
    uri: `/ide-manager/api/workspaces`,
    data: {
      name,
      displayName,
      description,
      projectId,
      projectName,
      profileId,
      profileName,
      namespaces,
      username,
      resourceQuota,
      selectedUsers,
      selectedInfraClass,
      selectedNodeTaint,
      selectedStorageTypes,
      defaultSettings,
      selectedAdministrator,
      selectedImages,
    },
    query: {},
  });

/*
 * Workspace 업데이트
 * @param {{ projectId:string }} param0
 */
export const updateWorkspace = ({
  workspaceId,
  name,
  displayName,
  description,
  projectId,
  projectName,
  profileId,
  profileName,
  namespaces,
  username,
  resourceQuota,
  selectedUsers,
  selectedInfraClass,
  selectedNodeTaint,
  selectedStorageTypes,
  defaultSettings,
  selectedAdministrator,
  selectedImages,
}) =>
  workspaceRequest({
    method: "put",
    uri: `/ide-manager/api/workspaces/${workspaceId}`,
    data: {
      name,
      displayName,
      description,
      projectId,
      projectName,
      profileId,
      profileName,
      namespaces,
      username,
      resourceQuota,
      selectedUsers,
      selectedInfraClass,
      selectedNodeTaint,
      selectedStorageTypes,
      defaultSettings,
      selectedAdministrator,
      selectedImages,
    },
    query: {},
  });

/*
 * Workspace 전체 조회
 * @param {{ name:string }} param0
 */
export const getWorkspace = () =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces`,
  });
/*
 * Workspace Id 조회
 * @param {{ name:string }} param0
 */
export const getWorkspaceById = ({ workspaceId }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/${workspaceId}`,
  });
/**
 * Workspace 중복 조회 (이름)
 */
export const isDuplcatedWorkspaceName = ({ name }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/isDuplicated`,
    query: {
      name,
    },
  });

/**
 * Workspace 삭제
 * @param {{id:string}} param0
 */
export const removeWorkspace = ({ id }) =>
  workspaceRequest({
    method: "delete",
    uri: `/ide-manager/api/workspaces/${id}`,
  });

/**
 * Workspace에서 사용할 RDE Infra 기준 정보 조회 (RDE Limit Range)
 */
export const getInfraBaseline = () =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/infra-class`,
  });

/**
 * Workspace에서 사용할 RDE Infra 기준 정보 조회 (RDE Limit Range)
 */
export const getInfraByWorkspaceId = ({ workspaceId }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/infra-class/${workspaceId}`,
  });

/**
 * 원격 개발 환경 목록
 * @param {{workspaceId:string}} param0
 */
export const getWorkspaceRdes = ({ workspaceId }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/${workspaceId}`,
  });

/**
 * 원격 개발 환경 조회 (Workspace, 계정에 속한 모든 RDE를 반환한다. - 다건 조회)
 * @param {{workspaceId:string,userName:string}} param0
 */
export const getWorkspaceRde = ({ workspaceId, userName }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/${workspaceId}`,
    query: {
      userName,
    },
  });

/**
 * 원격 개발 환경 조회 - RDE ID로 조회한다. (단건 조회)
 * @param {{rdeId:string}} param0
 */
export const getWorkspaceRdeById = ({ rdeId }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/ide-configs/custom-resource/user`,
    query: {
      rdeId: rdeId,
    },
  });

/**
 * 원격 개발 환경 추가
 * @param {{workspaceId:string, domainName:string, namespace:string, name:string, ideConfigSpec:object}} param0
 */
export const addWorkspaceRde = ({
  workspaceId,
  domainName,
  namespace,
  name,
  ideConfigSpec,
}) =>
  workspaceRequest({
    method: "post",
    uri: `/ide-manager/api/ide-configs/custom-resource`,
    data: {
      ...ideConfigSpec,
    },
    query: {
      workspaceId,
      domainName,
      namespace,
      name,
    },
  });
/**
 * 원격 개발 환경 다건 추가
 * @param {{workspaceId:string, domainName:string, dataList:object, namespace:string}} param0
 */
export const addWorkspaceRdeMulti = ({
  workspaceId,
  domainName,
  dataList,
  namespace,
}) =>
  workspaceRequest({
    method: "post",
    uri: `/ide-manager/api/ide-configs/custom-resource`,
    data: {
      dataList,
    },
    query: {
      workspaceId,
      domainName,
      namespace,
      multi: true,
    },
  });
/**
 * 원격 개발 환경 수정
 * @param {{rdeId:string, ideConfigSpec:object}} param0
 */
export const editWorkspaceRde = ({ rdeId, ideConfigSpec }) =>
  workspaceRequest({
    method: "put",
    uri: `/ide-manager/api/ide-configs/custom-resource`,
    data: {
      ...ideConfigSpec,
    },
    query: {
      rdeId,
    },
  });

/**
 * 원격 개발환경 상태 변경
 * 상태 정보: Stop, Resume, Shutdown, Shutdown
 * @param {{rdeId:string, status:string}} param0
 */
export const updateRdeStatus = ({ rdeId, status }) =>
  workspaceRequest({
    method: "patch",
    uri: `/ide-manager/api/ide-configs/custom-resource`,
    query: {
      rdeId: rdeId,
      status: status,
    },
  });

/**
 * 원격 개발환경 삭제
 * @param {{id:string, domainName:string}} param0
 */
export const removeWorkspaceRde = ({ id, domainName }) =>
  workspaceRequest({
    method: "delete",
    uri: `/ide-manager/api/ide-configs/custom-resource`,
    query: {
      id,
      domainName,
    },
  });

/**
 * RDE Pod 상태 조회
 * @param {{id:string, name:string, namespace:string}} param0
 */
export const getRdeStatus = ({ id, name, namespace }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/ide-configs/status/${id}`,
    query: {
      name,
      namespace,
    },
  });

export const getWorkerNodeTaint = (value, effect) => {
  const query = {};

  if (value) {
    query.value = value;
  }
  if (effect) {
    query.effect = effect;
  }

  return workspaceRequest({
    method: "get",
    uri: "/ide-manager/api/metrics/node-taint",
    query: query,
  });
};

export const getWorkerNodeTaintByProjectProfile = (projectId, profileId) => {
  if (!projectId || !profileId) return null;

  return workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/metrics/node-taint/project/${projectId}/profile/${profileId}`,
  });
};

/**
 * 워크스페이스 (CPU, Memory) 메트릭 조회
 * @param {{id:string}} param0
 */
export const getMetricsWorkspace = ({ id }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/metrics/workspace/${id}`,
  });

/**
 * 전체 워크스페이스 (CPU, Memory) 메트릭 조회
 */
export const getAllMetricsWorkspace = () =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/metrics/workspace`,
  });

// Get metrics for rde state, calling from ags-backend
// export const getRDEStateMetrics = (params = {}) => {
//   return new Promise((resolve, reject) => {
//     httpClient
//       .get("/dashboard/rde-dashboard/rde-detail-metrics", {
//         params,
//         paramsSerializer: (params) => {
//           return qs.stringify(params, { arrayFormat: "repeat" });
//         },
//         // currently this api is used in ags-backend
//         baseURL:
//           process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
//           "https://ags-dashboard.amdp-dev.skamdp.org",
//       })
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response);
//       });
//   });
// };

export const getRDEStateMetrics = (params) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/metrics/rde-detail-metrics`,
    query: params,
  });

/**
 * Cluster RDE Domain 정보 조회
 * @param {{clusterId:number}} param0
 */
export const getClusterDomain = ({ clusterId }) => {
  return workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/domains/${clusterId}`,
  });
};

/**
 * Cluster RDE Domain 정보 저장
 * @param {{clusterId: number, clusterName:string, rde_proxy_url:string}} param0
 */
export const addClusterDomain = ({ clusterId, clusterName, rde_proxy_url }) =>
  workspaceRequest({
    method: "post",
    uri: `/ide-manager/api/domains`,
    data: {
      clusterId,
      clusterName: clusterName,
      domainURL: rde_proxy_url,
    },
  });

/**
 * Cluster RDE Domain 정보 수정
 * @param {{clusterId: number, clusterName:string, rde_proxy_url:string}} param0
 */
export const updateClusterDomain = ({
  clusterId,
  clusterName,
  rde_proxy_url,
}) => {
  workspaceRequest({
    method: "put",
    uri: `/ide-manager/api/domains/${clusterId}`,
    data: {
      clusterName: clusterName,
      domainURL: rde_proxy_url,
    },
  });
};

/**
 * Cluster RDE Domain 정보 삭제
 * @param {{clusterId:number}} param0
 */
export const removeClusterDomain = ({ clusterId }) => {
  workspaceRequest({
    method: "delete",
    uri: `/ide-manager/api/domains/${clusterId}`,
  });
};

/**
 * Workspace에 등록 가능한 Project List 조회
 * 권한, RDE 관련 Proxy Url 등록 여부
 */
export const getAvailableProject = () => {
  return workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/common/project`,
  });
};

/**
 * Workspace에 등록 가능한 Profile List 조회
 * 등록된 Cluster에 Remote RDE Url이 등록이 되어있어야함.
 * @param {{projectId:string, projectName:string}} param0
 */
export const getAvailableProfile = ({ projectId, projectName }) => {
  return workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/common/project/${projectId}`,
    query: {
      name: projectName,
    },
  });
};

export const forceRestartRde = ({ rdeId }) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/ide-configs/force-restart/${rdeId}`,
  });

export const getSummaryRDEState = (rdeID) => {
  return workspaceRequest({
    method: "get",
    uri: `ide-manager/api/ide-configs/summary/${rdeID}`,
  });
};

export const getEventsRDEState = (rdeID) => {
  return workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/ide-configs/events/${rdeID}`,
  });
};

export const getWorkspaceByUser = (username) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/username/${username}`,
  });

export const getLogsRDEState = (rdeID, container) => {
  let uri = `/ide-manager/api/ide-configs/logs/${rdeID}`;

  if (container) {
    uri = uri + `?container=${container}`;
  }

  return workspaceRequest({
    method: "get",
    uri,
  });
};

export const getExtentionRDEList = () => {
  return workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/extension/list`,
  });
};

export const getStorageType = (workspaceId) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/storageType${
      workspaceId ? `/${workspaceId}` : ""
    }`,
  });

export const getWorkspaceListMetrics = (params) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/metrics/workspace-list-metrics`,
    query: params,
  });

export const getWorkspaceDetailMetrics = (params) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/metrics/workspace-detail-metrics`,
    query: params,
  });
export const getRdeListMetrics = (params) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/metrics/rde-list-metrics`,
    query: params,
  });

export const getInstallPackageByWorkspace = (workspaceId, namespace) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/extension/install-package/${workspaceId}?namespace=${namespace}`,
  });

export const getUserWorkspaceRole = (userName) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/user/${userName}/auths`,
  });

export const getDockerImages = () =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/workspaces/docker-images`,
  });

export const setWorkspaceFavorite = (username, workspaceId) =>
  workspaceRequest({
    method: "post",
    uri: `/ide-manager/api/user/${username}/favorite/workspace/${workspaceId}`,
  });

export const unsetWorkspaceFavorite = (username, workspaceId) =>
  workspaceRequest({
    method: "delete",
    uri: `/ide-manager/api/user/${username}/favorite/workspace/${workspaceId}`,
  });

export const setRdeFavorite = (username, rdeId) =>
  workspaceRequest({
    method: "post",
    uri: `/ide-manager/api/user/${username}/favorite/rde/${rdeId}`,
  });

export const unsetRdeFavorite = (username, rdeId) =>
  workspaceRequest({
    method: "delete",
    uri: `/ide-manager/api/user/${username}/favorite/rde/${rdeId}`,
  });

export const getSshInformation = (rdeId) =>
  workspaceRequest({
    method: "get",
    uri: `/ide-manager/api/ide-configs/ssh-access/${rdeId}`,
  });

/**
 * @typedef {object} RemoteDevelopmentEnv - 원격 개발 환경 파라메터
 * @property {string} userName - 사용자명
 * @property {string} userId - 사용자 아이디
 * @property {number} replicas - 레플리카 수량
 * @property {["vscode"|"webssh"]} serviceTypes - 서비스타입
 * @property {Port[]} portList - 포트 목록
 * @property {{permission:WebSshPermission}} webssh - ssh 설정
 * @property {{useGit:boolean, git:VscodeGit}} vscode - vscode 설정
 * @property {resourceSize} resourceSize - 인프라 설정
 *
 * @typedef {object} WebSshPermission
 * @property {"create"|"use"} useType - 권한 타입
 * @property {"administrator"|"architect"|"developer"|"coder"} [role] - 권한의 역할
 * @property {"cluster"|"namespace"} [scope] - 권한의 범위
 * @property {string} [serviceAccountName] -
 * @property {object} [additionalProperties] -
 *
 * @typedef {object} VscodeGit - vscode 설정의 git 정보
 * @property {string} id - git id
 * @property {string} repository - git repository
 * @property {string} [branch] - git branch
 * @property {string} [token] - git token
 *
 * @typedef {object} Port - 포트 정보
 * @property {string} name - 포트명
 * @property {"TCP"} protocol - 프로토콜
 * @property {number} port - 포트
 * @property {number} targetPort - 대상 포트
 *
 * @typedef {object} resourceSize - 인프라 설정
 * @property {string} cpu - cpu 정보
 * @property {string} memory - 메모리 정보
 * @property {string} disk - 디스크 정보
 */
